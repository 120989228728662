import { formatScore } from '../../../../../shared/helpers/formatters';
import { GoldDrillIcon } from '../../../../../shared/icons/GoldDrillIcon';
import { Button } from '../../../../../shared/ui/Button';

import s from './FoundTreasureModal.module.css';

export const FoundTreasureModal = ({ visible, amount, onClose }) => {
  return (
    <section className={`${s.outer} ${visible ? s.visible : ''}`}>
      <div className={s.inner}>
        <div className={s.heading}>YOU HAVE FOUND THE TREASURE!</div>

        <div className={s.amount}>
          {formatScore(amount || 0)} <GoldDrillIcon />
        </div>

        <Button className={s.btn} onClick={onClose}>
          GET IT!
        </Button>
      </div>
    </section>
  );
};

import { ArrowAltLeftIcon } from '../../../shared/icons/ArrowAltLeftIcon';
import {
  useRouterProvider,
  ROUTES,
} from '../../../shared/contexts/router-context';
import { useTourProvider } from '../../../shared/contexts/tour-context';

import s from './Header.module.css';

const questsTabs = [
  { value: ROUTES.QUESTS_ACTIVE, label: 'Active' },
  { value: ROUTES.QUESTS_COMPLETED, label: 'Completed' },
];

const leaderboardTabs = [
  { value: ROUTES.LEADERBOARD_SQUADS, label: 'Squads' },
  { value: ROUTES.LEADERBOARD_FRIENDS, label: 'Friends' },
];

const headingsMap = {
  [ROUTES.EARN]: 'Earn',
  [ROUTES.QUESTS_ACTIVE]: 'Quests',
  [ROUTES.QUESTS_COMPLETED]: 'Quests',
  [ROUTES.REFERAL]: 'Referal',
  [ROUTES.LEADERBOARD_SQUADS]: 'Leaderboard',
  [ROUTES.LEADERBOARD_SQUAD_CREATE]: 'Creatie a squad',
  [ROUTES.LEADERBOARD_SQUAD_EDIT]: 'Edit your squad',
  [ROUTES.LEADERBOARD_FRIENDS]: 'Leaderboard',
  [ROUTES.STAKING]: 'Staking',
};

export const Header = () => {
  const { route, setRoute } = useRouterProvider();
  const { tourEnabled } = useTourProvider();

  let tabs = null;

  if (route === ROUTES.QUESTS_ACTIVE || route === ROUTES.QUESTS_COMPLETED) {
    tabs = questsTabs;
  } else if (
    route === ROUTES.LEADERBOARD_FRIENDS ||
    route === ROUTES.LEADERBOARD_SQUADS
  ) {
    tabs = leaderboardTabs;
  }

  return (
    <header className={`${s.header} ${tourEnabled ? s.disabled : ''}`}>
      {(route === ROUTES.LEADERBOARD_SQUAD_CREATE ||
        route === ROUTES.LEADERBOARD_SQUAD_EDIT) && (
        <ArrowAltLeftIcon
          className={s.leftIcon}
          onClick={() => setRoute(ROUTES.LEADERBOARD_SQUADS)}
        />
      )}

      <div className={s.heading}>{headingsMap[route]}</div>

      {!!tabs && (
        <div className={s.tabs}>
          {tabs.map(({ value, label }) => (
            <div
              key={value}
              className={`${s.tab} ${value === route ? s.tabActive : ''}`}
              onClick={() => setRoute(value)}
            >
              {label}
            </div>
          ))}
        </div>
      )}
    </header>
  );
};

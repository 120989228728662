import Phaser from 'phaser';

import { BootScene } from './scenes/BootScene';
import { WorldScene } from './scenes/WorldScene';

import { TAIL_SIZE, SCALE_FACTOR } from './constants';

export const EventBus = new Phaser.Events.EventEmitter();

export const startGame = ({
  parent = 'game-container',
  width = 600,
  height = 800,
}) =>
  new Phaser.Game({
    type: Phaser.AUTO,
    width,
    height,
    parent,
    backgroundColor: '#028af8',
    scene: [BootScene, WorldScene],
    render: {
      pixelArt: false,
      antialias: true,
      mipmapFilter: 'LINEAR_MIPMAP_LINEAR',
    },
  });

export const renderPreviewTextureImages = (textures) => {
  return textures.map(({ offsetTop, offsetLeft, width, name, src }, index) => {
    const style = {};
    const scale = TAIL_SIZE / 100;
    if (offsetTop || offsetLeft) {
      style.width = width / scale + '%';
    }
    if (offsetTop) {
      style.top = (offsetTop * SCALE_FACTOR) / scale + '%';
    }
    if (offsetLeft) {
      style.left = (offsetLeft * SCALE_FACTOR) / scale + '%';
    }
    return (
      <img
        key={`${name}:${index}`}
        src={`/assets/diggy-game/${src}`}
        alt=""
        style={style}
      />
    );
  });
};

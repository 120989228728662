import { useEffect } from 'react';

import { useAppProvider } from '../../../shared/contexts/app-context';
import {
  useTourProvider,
  TOUR_STEPS,
} from '../../../shared/contexts/tour-context';
import {
  useStartClaimMutation,
  useCompleteClaimMutation,
} from '../../../app/store';
import { EarnIcon } from '../../../shared/icons/EarnIcon';
import { GoldDrillIcon } from '../../../shared/icons/GoldDrillIcon';
import { formatScore } from '../../../shared/helpers/formatters';

import {
  defaultAnimState,
  getAnimStyle,
  randomInt,
  rnd,
  getTimeLabel,
  getPercent,
} from './utils';
import { EarnBlock } from './EarnBlock';

import s from './EarnView.module.css';

let handleTap = null;

const handlePointerUp = (e) => {
  const target = e.currentTarget;
  const parent = target.parentNode;
  const o = parent.getBoundingClientRect();
  const r = { ...defaultAnimState };
  r.scale = 0.9;
  target.style.transform = getAnimStyle(r);
  setTimeout(() => {
    target.style.transform = getAnimStyle(defaultAnimState);
  }, 150);
  const topInitial = e.clientY - o.top + randomInt(-15, 15);
  const leftInitial = e.clientX - o.left + randomInt(-15, 15);
  const div = document.createElement('div');
  div.className = s.tapValue;
  div.style.top = `${topInitial}px`;
  div.style.left = `${leftInitial}px`;
  div.style.transform = `scale(${rnd(0.9, 1.3)}) translateZ(0px) rotate(${rnd(
    -15,
    15
  )}deg)`;
  div.innerHTML = '+1';
  parent.appendChild(div);
  setTimeout(() => {
    div.style.top = `${topInitial - randomInt(100, 150)}px`;
    div.style.left = `${leftInitial - randomInt(-30, 30)}px`;
    div.style.transform = `scale(${rnd(0.9, 1.3)}) translateZ(0px) rotate(${rnd(
      -15,
      15
    )}deg)`;
    div.style.opacity = 0;
  }, 50);
  setTimeout(() => {
    div.remove();
  }, 3100);
  if (handleTap) {
    handleTap();
  }
};

export const EarnView = () => {
  const {
    profile,
    balancePointsTotal,
    farmedPoints,
    pointsTapped,
    claimDuration,
    claimDurationLeft,
    isFarmingCompleted,
    isFarmingStarted,
    energy,
    squad,
    joinedSquad,
    onTap,
  } = useAppProvider();
  const { tourEnabled, tourStep, setTourStep } = useTourProvider();
  const [startClaim] = useStartClaimMutation();
  const [completeClaim, { isLoading }] = useCompleteClaimMutation();

  handleTap = onTap;

  const isTapDisabled = energy.current <= 0;

  useEffect(() => {
    if (tourEnabled) {
      const container = document.getElementById('app-layout-inner');
      const handler = () => {
        container.scrollTop = 10000;
      };

      handler();

      window.addEventListener('resize', handler);
      container.addEventListener('scroll', handler);

      return () => {
        window.removeEventListener('resize', handler);
        container.removeEventListener('scroll', handler);
      };
    }
  }, [tourEnabled]);

  return (
    <section
      className={`${s.outer} ${tourEnabled ? s[`tour-${tourStep}`] : ''}`}
    >
      <div className={s.body}>
        <EarnBlock
          title="Earned"
          variant="orange"
          amount={balancePointsTotal + pointsTapped}
        />

        <EarnBlock
          title="Farming"
          variant="green"
          amount={farmedPoints}
          squadFactor={squad || joinedSquad ? '1.25' : null}
        />

        <div className={s.energy}>
          <span className={s.energyHidden}>
            {energy.max}/{energy.max}
          </span>
          <span className={s.energyVisible}>
            {energy.current}/{energy.max}
          </span>
          <span className={s.energySign}>⚡️</span>
        </div>

        <div className={s.intro}>Every click turns into treasure</div>
      </div>

      <div className={s.tapOuter}>
        <div
          className={`${s.tapBox} ${isTapDisabled ? s.tapBoxDisabled : ''}`}
          onPointerUp={isTapDisabled ? undefined : handlePointerUp}
        >
          <EarnIcon />
        </div>
      </div>

      <div className={s.claimOuter}>
        {tourStep === TOUR_STEPS.CLAIM_START && (
          <div className={s.tourInfo}>
            <div className={s.tourInfoHeading}>
              {!isFarmingStarted && !isFarmingCompleted
                ? 'PRESS TO GET'
                : isFarmingCompleted
                ? 'Get it!'
                : 'wait'}
            </div>
            <div className={s.tourInfoAmount}>
              {formatScore(profile?.claim_amount_base ?? 0)} <GoldDrillIcon />
            </div>
          </div>
        )}

        <div
          className={`${s.claim} ${
            (isFarmingStarted && !isFarmingCompleted) || isLoading
              ? ''
              : s.claimActive
          }`}
          onClick={() => {
            if (!isFarmingStarted) {
              startClaim();
            } else if (isFarmingCompleted && !isLoading) {
              completeClaim()
                .unwrap()
                .then(() => {
                  if (tourEnabled) {
                    setTourStep(TOUR_STEPS.CLAIM_GO_TO_STAKE);
                  }
                });
            }
          }}
        >
          <div className={s.claimInner}>
            {getTimeLabel(claimDuration, claimDurationLeft, tourEnabled)}
          </div>

          {isFarmingStarted && (
            <div
              className={s.claimStripe}
              style={{
                width: `${getPercent(claimDuration, claimDurationLeft)}%`,
              }}
            />
          )}
        </div>
      </div>
    </section>
  );
};

import { Scene, Math as PhaserMath } from 'phaser';

import { textures } from '../resources';
import { EventBus } from '../helpers';
import {
  SCALE_FACTOR,
  ZOOM_DEFAULT,
  ZOOM_MIN,
  ZOOM_MAX,
  SCENE_WIDTH,
  SCENE_HEIGHT,
  TAIL_SIZE,
} from '../constants';

let sceneMatrix = [];
const waterRow = new Array(30).fill(textures.WATER_1);

export const regenerateSceneMatrix = (map) => {
  sceneMatrix = [
    ...map.map((row) => [
      ...row.map((cells) => cells.map((textureName) => textures[textureName])),
      ...waterRow,
    ]),
    waterRow,
    waterRow,
    waterRow,
    waterRow,
    waterRow,
    waterRow,
  ];
};

export class WorldScene extends Scene {
  constructor() {
    super('WorldScene');

    this.selection = { rec: null, xFrame: null, yFrame: null };
    this.isSelectionBlocked = false;
  }

  create() {
    this.renderTails();

    const camera = this.cameras.main;

    camera.setBounds(0, 0, SCENE_WIDTH, SCENE_HEIGHT);
    camera.setZoom(ZOOM_DEFAULT);

    this.input.addPointer(1);

    let initialDistance = 0;
    let initialScale = 0;

    const getDoublePointersDistance = () =>
      this.input.pointer1.isDown && this.input.pointer2.isDown
        ? PhaserMath.Distance.Between(
            this.input.pointer1.x,
            this.input.pointer1.y,
            this.input.pointer2.x,
            this.input.pointer2.y
          )
        : null;
    const setZoom = (zoom) =>
      camera.setZoom(PhaserMath.Clamp(zoom, ZOOM_MIN, ZOOM_MAX));

    this.input.on('pointermove', (p) => {
      if (p.isDown) {
        camera.scrollX -= (p.x - p.prevPosition.x) / camera.zoom;
        camera.scrollY -= (p.y - p.prevPosition.y) / camera.zoom;
      }
      const distance = getDoublePointersDistance();
      if (distance) {
        setZoom(initialScale * (distance / initialDistance));
      } else if (p.pointerType === 'touch' && p.isDown && p.getDistance()) {
        setZoom(p.distance / 100);
      }
    });

    this.input.on('pointerdown', (pointer) => {
      const distance = getDoublePointersDistance();
      if (distance) {
        initialDistance = distance;
        initialScale = camera.zoom;
      }
    });

    this.input.on('wheel', (_pointer, _gameObjects, _deltaX, deltaY) => {
      setZoom(camera.zoom + deltaY * -0.005);
    });

    EventBus.on('modal-visibility-change', ({ visible }) => {
      this.isSelectionBlocked = visible;
    });
  }

  drawSelection(xFrame, yFrame) {
    const strokeWidth = TAIL_SIZE / 25;
    const strokeOffset = strokeWidth / 2;
    if (this.selection.rec) {
      this.selection.rec.destroy();
    }
    if (this.selection.xFrame === xFrame && this.selection.yFrame === yFrame) {
      this.selection.xFrame = null;
      this.selection.yFrame = null;
      this.selection.rec = null;
      return false;
    }
    this.selection.xFrame = xFrame;
    this.selection.yFrame = yFrame;
    this.selection.rec = this.add
      .rectangle(
        xFrame * TAIL_SIZE + strokeOffset,
        yFrame * TAIL_SIZE + strokeOffset,
        TAIL_SIZE,
        TAIL_SIZE,
        0xffffff,
        0.3
      )
      .setOrigin(0, 0)
      .setStrokeStyle(TAIL_SIZE / 25, 0xffffff)
      .setDepth(10);
    return true;
  }

  renderTails() {
    sceneMatrix.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        const cells = Array.isArray(cell) ? cell : [cell];
        cells.forEach(
          (
            {
              name,
              baseWidth,
              baseHeight,
              width,
              height,
              angle = 0,
              offsetTop = 0,
              offsetLeft = 0,
            },
            index
          ) => {
            const sprite = this.add.sprite(
              cellIndex * (baseWidth || width) +
                width / 2 +
                offsetLeft * SCALE_FACTOR,
              rowIndex * (baseHeight || height) +
                height / 2 +
                offsetTop * SCALE_FACTOR,
              name
            );
            sprite.setDisplaySize(width, height);
            sprite.setAngle(angle);
            sprite.setDepth(index);
            sprite.setInteractive();

            sprite.on('pointerup', (pointer) => {
              if (
                Math.abs(pointer.downX - pointer.upX) <= 5 &&
                Math.abs(pointer.downY - pointer.upY) <= 5 &&
                !this.isSelectionBlocked
              ) {
                const selected = this.drawSelection(cellIndex, rowIndex);
                EventBus.emit('sprite-click', {
                  x: cellIndex,
                  y: rowIndex,
                  cell,
                  rootCell: cells[0],
                  sprite,
                  pointer,
                  selected,
                });
              }
            });
          }
        );
      });
    });
    this.add
      .image(41 * SCALE_FACTOR, 63 * SCALE_FACTOR, textures.PATH_1.name)
      .setOrigin(0, 0)
      .setDisplaySize(202 * SCALE_FACTOR, 126 * SCALE_FACTOR);
  }
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { AppProvider } from './shared/contexts/app-context';
import { DiggyGameProvider } from './shared/contexts/diggy-game-context';
import { RouterProvider } from './shared/contexts/router-context';
import { TourProvider } from './shared/contexts/tour-context';

import { store } from './app/store';
import { App } from './app/ui/App';

import './shared/assets/index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider>
        <AppProvider>
          <DiggyGameProvider>
            <TourProvider>
              <App />
            </TourProvider>
          </DiggyGameProvider>
        </AppProvider>
      </RouterProvider>
    </Provider>
  </React.StrictMode>
);

import { useEffect, useState } from 'react';

import { useAppProvider } from '../../../shared/contexts/app-context';
import { SquadViewProvider } from '../../../shared/contexts/squad-view-context';
import { useTourProvider } from '../../../shared/contexts/tour-context';

import { Header } from '../../../widgets/ui/Header';
import { TabBar } from '../../../widgets/ui/TabBar';
import { AppLoader } from '../../../widgets/ui/AppLoader';

import s from './AppLayout.module.css';

export const AppLayout = ({ children }) => {
  const { hasProfile } = useAppProvider();
  const { tourEnabled, tourModals } = useTourProvider();

  const [contentEnabled, setContentEnabled] = useState(false);

  useEffect(() => {
    if (tourEnabled) {
      const timer = setTimeout(() => setContentEnabled(true), 500);
      return () => clearTimeout(timer);
    }
  }, [tourEnabled]);

  return (
    <section className={s.outer}>
      <SquadViewProvider>
        {hasProfile && (!tourEnabled || contentEnabled) ? (
          <>
            <Header />

            <section className={s.inner} id="app-layout-inner">
              {children}

              {tourModals}
            </section>

            <TabBar />
          </>
        ) : (
          <AppLoader />
        )}
      </SquadViewProvider>
    </section>
  );
};

export const CellPosDelimiterIcon = () => {
  return (
    <svg
      width="4"
      height="14"
      viewBox="0 0 4 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.701795 5.31335C0.0951155 5.31335 -0.00195312 4.6096 -0.00195312 3.56612C-0.00195312 3.15357 0.0951155 2.20715 0.0951155 1.67328C0.0951155 0.945263 0.48339 0.896729 0.847398 0.896729H3.44398C3.58959 0.896729 3.95359 1.2122 3.95359 1.67328C3.95359 2.74103 4.00213 2.95944 4.00213 4.09999C4.00213 4.58534 3.78372 5.19202 3.44398 5.19202C3.03144 5.19202 2.27916 5.31335 0.701795 5.31335ZM0.701795 13.1031C0.0951155 13.1031 -0.00195312 12.3994 -0.00195312 11.3559C-0.00195312 10.9433 0.0951155 9.99691 0.0951155 9.46304C0.0951155 8.73502 0.48339 8.68649 0.847398 8.68649H3.44398C3.58959 8.68649 3.95359 9.00196 3.95359 9.46304C3.95359 10.5308 4.00213 10.7492 4.00213 11.8898C4.00213 12.3751 3.78372 12.9818 3.44398 12.9818C3.03144 12.9818 2.27916 13.1031 0.701795 13.1031Z"
        fill="#50312C"
      />
    </svg>
  );
};

export const SeparatorIcon = () => {
  return (
    <svg
      width="3"
      height="7"
      viewBox="0 0 3 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.514032 2.64292C0.182448 2.64292 0.129395 2.25829 0.129395 1.68796C0.129395 1.46248 0.182448 0.945214 0.182448 0.65342C0.182448 0.255519 0.394662 0.228992 0.593612 0.228992H2.01279C2.09237 0.228992 2.29132 0.401416 2.29132 0.65342C2.29132 1.23701 2.31785 1.35638 2.31785 1.97976C2.31785 2.24502 2.19848 2.57661 2.01279 2.57661C1.78731 2.57661 1.37615 2.64292 0.514032 2.64292ZM0.514032 6.90046C0.182448 6.90046 0.129395 6.51582 0.129395 5.9455C0.129395 5.72002 0.182448 5.20275 0.182448 4.91096C0.182448 4.51306 0.394662 4.48653 0.593612 4.48653H2.01279C2.09237 4.48653 2.29132 4.65895 2.29132 4.91096C2.29132 5.49455 2.31785 5.61392 2.31785 6.23729C2.31785 6.50256 2.19848 6.83415 2.01279 6.83415C1.78731 6.83415 1.37615 6.90046 0.514032 6.90046Z"
        fill="white"
      />
    </svg>
  );
};

import { useEffect, useRef, useState } from 'react';

import { useAppProvider } from '../../../shared/contexts/app-context';
import { useDiggyGameProvider } from '../../../shared/contexts/diggy-game-context';
import {
  useTourProvider,
  TOUR_STEPS,
} from '../../../shared/contexts/tour-context';
import { formatScore } from '../../../shared/helpers/formatters';
import { ArrowRightIcon } from '../../../shared/icons/ArrowRightIcon';
import { GoldDrillIcon } from '../../../shared/icons/GoldDrillIcon';
import { Button } from '../../../shared/ui/Button';

import { startGame, EventBus } from './lib/helpers';
import { tileTypes } from './lib/resources';
import { regenerateSceneMatrix } from './lib/scenes/WorldScene';

import { IntroModal } from './ui/IntroModal';
import { FoundTreasureModal } from './ui/FoundTreasureModal';
import { StolenTreasureModal } from './ui/StolenTreasureModal';
import { ToStakeModal } from './ui/ToStakeModal';
import { YourStakesModal } from './ui/YourStakesModal';
import { StakeEmptyModal } from './ui/StakeEmptyModal';
import { StakedCoinsModal } from './ui/StakedCoinsModal';
import { FoundAndStakedCoinsModal } from './ui/FoundAndStakedCoinsModal';
import { DigUpYourCoinsModal } from './ui/DigUpYourCoinsModal';
import { CellPlaceInfo } from './ui/CellPlaceInfo';

import s from './DiggyGame.module.css';

const containerId = 'diggy-game-container';

const getContainerSizes = (node) => ({
  width: node.offsetWidth,
  height: node.offsetHeight,
});

const tourStakeRobStakeIntroText = (
  <>
    And now we will show you <br /> something else... <br /> Choose another
    place and select it
  </>
);

export const DiggyGame = () => {
  const { balancePointsTotal } = useAppProvider();
  const {
    map,
    energy,
    energyMax,
    stakes,
    forceReloadStatus,
    stakesStolen,
    selectedCell,
    setSelectedCell,
  } = useDiggyGameProvider();
  const { tourEnabled, tourStep, setTourStep } = useTourProvider();

  const game = useRef(null);
  const currentScene = useRef(null);
  const containerRef = useRef();

  const [introVisible, setIntroVisible] = useState(false);
  const [foundTreasureVisible, setFoundTreasureVisible] = useState(false);
  const [stolenTreasureVisible, setStolenTreasureVisible] = useState(false);
  const [toStakeVisible, setToStakeVisible] = useState(false);
  const [yourStakesVisible, setYourStakesVisible] = useState(false);
  const [stakeEmptyVisible, setStakeEmptyVisible] = useState(false);
  const [stakedCoinsVisible, setStakedCoinsVisible] = useState(false);
  const [foundAndStakedCoinsVisible, setFoundAndStakedCoinsVisible] =
    useState(false);
  const [digUpYourCoinsVisible, setDigUpYourCoinsVisible] = useState(false);
  const [lastDigUpAmount, setLastDigUpAmount] = useState(null);
  const [stakedCoinsAmount, setStakedCoinsAmount] = useState(null);
  const [stakedCoinsLevel, setStakedCoinsLevel] = useState(null);
  const [stolenStakes, setStolenStakes] = useState(null);
  const [digUpYourCoinsAmount, setDigUpYourCoinsAmount] = useState(null);
  const [digUpYourCoinsProfit, setDigUpYourCoinsProfit] = useState(null);

  const canDigOnCell =
    selectedCell && selectedCell.rootCell.type === tileTypes.SAND;
  const isTourStakeYourStakesIntro =
    tourEnabled && tourStep === TOUR_STEPS.STAKE_YOUR_STAKES_INTRO;
  const isTourStakeRobStakeIntro =
    tourEnabled && tourStep === TOUR_STEPS.STAKE_ROB_STAKE_INTRO;

  useEffect(() => {
    const sizes = getContainerSizes(containerRef.current);

    if (!game.current) {
      game.current = startGame({ parent: containerId, ...sizes });
    }

    EventBus.on('current-scene-ready', (currentScene) => {
      currentScene.current = currentScene;
    });

    EventBus.on('sprite-click', (scene) => {
      setSelectedCell(scene.selected ? scene : null);
    });

    const resizeHandler = () => {
      if (game.current) {
        const sizes = getContainerSizes(containerRef.current);

        game.current.scale.resize(sizes.width, sizes.height);
        game.current.scene.getScenes(true).forEach((scene) => {
          scene.cameras.main.setSize(sizes.width, sizes.height);
        });
      }
    };

    window.addEventListener('resize', resizeHandler);

    return () => {
      if (game.current) {
        game.current.destroy(true);
        game.current = null;
        currentScene.current = null;
      }

      EventBus.removeListener('current-scene-ready');
      EventBus.removeListener('sprite-click');

      window.removeEventListener('resize', resizeHandler);
    };
  }, [setSelectedCell]);

  useEffect(() => {
    regenerateSceneMatrix(map);
    if (game.current) {
      game.current.scene.getScenes(true).forEach((scene) => {
        if (scene.scene.key === 'WorldScene') {
          scene.scene.restart();
        }
      });
    }
  }, [map]);

  useEffect(() => {
    const key = 'diggy-game-intro-showed';
    if (localStorage.getItem(key) !== 'true') {
      const timer = setTimeout(() => {
        setIntroVisible(true);
        localStorage.setItem(key, 'true');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, []);

  useEffect(() => {
    if (stakesStolen) {
      setStolenStakes(stakesStolen);
      setStolenTreasureVisible(true);
    }
  }, [stakesStolen]);

  useEffect(() => {
    const visible =
      introVisible ||
      foundTreasureVisible ||
      stolenTreasureVisible ||
      toStakeVisible ||
      yourStakesVisible ||
      stakeEmptyVisible ||
      stakedCoinsVisible ||
      foundAndStakedCoinsVisible ||
      digUpYourCoinsVisible;

    const timer = setTimeout(
      () => {
        EventBus.emit('modal-visibility-change', {
          visible,
        });
      },
      visible ? 0 : 300
    );

    forceReloadStatus(yourStakesVisible);

    return () => {
      clearTimeout(timer);
    };
  }, [
    introVisible,
    foundTreasureVisible,
    stolenTreasureVisible,
    toStakeVisible,
    yourStakesVisible,
    stakeEmptyVisible,
    stakedCoinsVisible,
    foundAndStakedCoinsVisible,
    forceReloadStatus,
    digUpYourCoinsVisible,
  ]);

  useEffect(() => {
    if (tourEnabled && tourStep === TOUR_STEPS.STAKE_YOUR_STAKES_INTRO) {
      const container = document.querySelector(
        '#app-layout-inner .scroll-area'
      );
      const handler = () => {
        container.scrollTop = 10000;
      };

      handler();

      window.addEventListener('resize', handler);
      container.addEventListener('scroll', handler);

      return () => {
        window.removeEventListener('resize', handler);
        container.removeEventListener('scroll', handler);
      };
    }
  }, [tourEnabled, tourStep]);

  return (
    <>
      <section
        className={`${s.outer} ${canDigOnCell ? s.canDig : ''} ${
          tourEnabled ? s[`tour-${tourStep}`] || '' : ''
        }`}
      >
        <div className={`${s.scroller} scroll-area`}>
          <div className={s.counters}>
            <div className={s.counter}>
              {formatScore(balancePointsTotal)} <GoldDrillIcon />
            </div>

            <div className={s.counter}>
              {energy}/{energyMax}⚡
            </div>
          </div>

          <div className={s.inner}>
            <div className={s.container} id={containerId} ref={containerRef} />
          </div>

          <CellPlaceInfo
            className={s.cellPlaceInfo}
            classNames={{
              heading: s.cellPlaceInfoHeading,
              posDelim: s.cellPlaceInfoPosDelim,
            }}
            selectText={
              isTourStakeRobStakeIntro
                ? tourStakeRobStakeIntroText
                : tourEnabled && !canDigOnCell
                ? 'choose the place'
                : undefined
            }
            confirmText={
              isTourStakeRobStakeIntro ? tourStakeRobStakeIntroText : undefined
            }
          />

          <div className={s.buttons}>
            <Button
              variant="green"
              className={`${s.buttonsBtn} ${s.buttonsBtnSelect}`}
              disabled={!canDigOnCell}
              onClick={() => {
                if (canDigOnCell) {
                  setToStakeVisible(true);
                  if (tourEnabled && canDigOnCell) {
                    setTourStep(
                      isTourStakeRobStakeIntro
                        ? TOUR_STEPS.STAKE_ROB_STAKE_CHOOSE
                        : TOUR_STEPS.STAKE_START_STAKING
                    );
                  }
                }
              }}
            >
              Select
            </Button>

            <div className={s.buttonsBtnStakesOuter}>
              {isTourStakeYourStakesIntro && (
                <div className={s.tourIntro}>
                  <div className={s.tourIntroText}>
                    Now you have stake. <br />
                    here you can track your profit <br />
                    and stakes
                  </div>
                  <ArrowRightIcon />
                </div>
              )}

              <Button
                className={s.buttonsBtn}
                disabled={!stakes.length}
                onClick={() => {
                  setYourStakesVisible(true);
                  if (isTourStakeYourStakesIntro) {
                    setTourStep(TOUR_STEPS.STAKE_YOUR_STAKES_DIG_UP);
                  }
                }}
              >
                Your stakes
              </Button>
            </div>
          </div>
        </div>
      </section>

      <IntroModal
        visible={introVisible}
        onClose={() => setIntroVisible(false)}
      />

      <FoundTreasureModal
        visible={foundTreasureVisible}
        amount={lastDigUpAmount}
        onClose={() => setFoundTreasureVisible(false)}
      />

      <StolenTreasureModal
        visible={stolenTreasureVisible}
        stakes={stolenStakes}
        onClose={() => setStolenTreasureVisible(false)}
      />

      <ToStakeModal
        visible={toStakeVisible}
        cell={selectedCell}
        onClose={() => setToStakeVisible(false)}
        onComplete={({ digUpAmount, stakedAmount, level }) => {
          setToStakeVisible(false);
          if (stakedAmount && digUpAmount) {
            setStakedCoinsAmount(stakedAmount);
            setLastDigUpAmount(digUpAmount);
            setStakedCoinsLevel(level);
            setFoundAndStakedCoinsVisible(true);
          } else if (stakedAmount) {
            setStakedCoinsAmount(stakedAmount);
            setStakedCoinsLevel(level);
            setStakedCoinsVisible(true);
          } else if (digUpAmount) {
            setLastDigUpAmount(digUpAmount);
            setFoundTreasureVisible(true);
          } else if (!stakedAmount && !digUpAmount) {
            setStakeEmptyVisible(true);
          }
        }}
      />

      <YourStakesModal
        visible={yourStakesVisible}
        onClose={() => setYourStakesVisible(false)}
        onFinish={({ amount, profit }) => {
          setYourStakesVisible(false);
          if (amount > 0) {
            setDigUpYourCoinsAmount(amount);
            setDigUpYourCoinsProfit(profit);
            setDigUpYourCoinsVisible(true);
          }
        }}
      />

      <StakeEmptyModal
        visible={stakeEmptyVisible}
        onClose={() => setStakeEmptyVisible(false)}
      />

      <StakedCoinsModal
        visible={stakedCoinsVisible}
        amount={stakedCoinsAmount}
        level={stakedCoinsLevel}
        onClose={() => setStakedCoinsVisible(false)}
      />

      <FoundAndStakedCoinsModal
        visible={foundAndStakedCoinsVisible}
        foundAmount={lastDigUpAmount}
        stakedAmount={stakedCoinsAmount}
        level={stakedCoinsLevel}
        onClose={() => setFoundAndStakedCoinsVisible(false)}
      />

      <DigUpYourCoinsModal
        visible={digUpYourCoinsVisible}
        amount={digUpYourCoinsAmount}
        profit={digUpYourCoinsProfit}
        onClose={() => setDigUpYourCoinsVisible(false)}
      />
    </>
  );
};

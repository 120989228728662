import { ArrowRightIcon } from '../../../../shared/icons/ArrowRightIcon';

import s from './TourStepIntro.module.css';

export const TourStepIntro = ({ visible, onNext }) => {
  return (
    <section className={`${s.outer} ${visible ? s.visible : ''}`}>
      <div className={s.heading}>WELCOME</div>
      <div className={s.text}>
        Welcome to <span>diggy world!</span> <br /> We can't wait to get
        started, <br /> so <span>get your first brand</span> and let's go!
      </div>

      <div className={s.arrow} onClick={onNext}>
        <ArrowRightIcon />
      </div>
    </section>
  );
};

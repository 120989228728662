import { DiggyGame } from '../../../features/games/DiggyGame';

import s from './StakingView.module.css';

export const StakingView = () => {
  return (
    <section className={s.outer}>
      <DiggyGame />
    </section>
  );
};

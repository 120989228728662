import { useEffect, useState } from 'react';

import { InputText } from '../../../shared/ui/InputText';
import { Button } from '../../../shared/ui/Button';
import { useAppProvider } from '../../../shared/contexts/app-context';
import {
  useRouterProvider,
  ROUTES,
} from '../../../shared/contexts/router-context';

import { useStoreSquadMutation } from '../../store';

import { SquadPicture } from './SquadPicture';

import s from './LeaderBoardSquadFormView.module.css';

export const LeaderBoardSquadFormView = ({ edit }) => {
  const { squadSocCodeDefault, squad } = useAppProvider();
  const { setRoute } = useRouterProvider();
  const [storeSquad, { isLoading }] = useStoreSquadMutation();
  const [form, setForm] = useState({
    file: null,
    name: '',
    ref_link: '',
    soc_link: '',
  });
  const [existedUrl, setExistedUrl] = useState(null);
  const [error, setError] = useState('');

  let canSave = !!form.name && !!form.ref_link && !!form.soc_link;

  if (canSave) {
    if (squad) {
      canSave = !!form.file || !!existedUrl;
    } else {
      canSave = !!form.file;
    }
  }

  useEffect(() => {
    setForm((form) => ({
      ...form,
      ref_link: form.ref_link || squadSocCodeDefault,
    }));
  }, [squadSocCodeDefault]);

  useEffect(() => {
    if (edit) {
      if (squad) {
        setForm({
          file: null,
          name: squad.name,
          ref_link: squad.ref_link,
          soc_link: squad.soc_link,
        });
        setExistedUrl(squad.file_path);
      } else {
        setRoute(ROUTES.LEADERBOARD_SQUADS);
      }
    }
  }, [edit, squad, setRoute]);

  return (
    <section className={s.outer}>
      <SquadPicture
        value={form.file || existedUrl || null}
        onChange={(file) => {
          setForm((form) => ({ ...form, file }));
          setError('');
        }}
      />

      {(!!existedUrl || !!form.file) && (
        <div
          className={s.removeLogo}
          onClick={() => {
            setExistedUrl(null);
            setForm((form) => ({ ...form, file: null }));
          }}
        >
          Remove logo
        </div>
      )}

      <InputText
        className={s.input}
        placeholder="Name"
        value={form.name}
        onChange={(e) => {
          setForm((form) => ({ ...form, name: e.target.value }));
          setError('');
        }}
      />

      <InputText
        className={s.input}
        label="Referral code"
        placeholder="Enter the code"
        value={form.ref_link}
        onChange={(e) => {
          setForm((form) => ({ ...form, ref_link: e.target.value }));
          setError('');
        }}
      />

      <InputText
        className={s.input}
        label="Your socials "
        placeholder="Enter the link"
        value={form.soc_link}
        onChange={(e) => {
          setForm((form) => ({ ...form, soc_link: e.target.value }));
          setError('');
        }}
      />

      {!!error && <div className={s.error}>{error}</div>}

      <Button
        disabled={!canSave || isLoading}
        className={s.button}
        onClick={() => {
          const data = new FormData();
          data.append('file', form.file);
          data.append('name', form.name);
          data.append('ref_link', form.ref_link);
          data.append('soc_link', form.soc_link);
          storeSquad(data)
            .unwrap()
            .then((res) => {
              if (res.success) {
                setRoute(ROUTES.LEADERBOARD_SQUADS);
              } else {
                const e = res.error;
                if (e === 'invalid') {
                  setError('Enter valid name, referral code and social link');
                } else if (['invalid:f', 'invalid:ft'].includes(e)) {
                  setError('Select valid image for logo');
                } else if (['exists:link', 'exists:link:i'].includes(e)) {
                  setError(
                    'This referral code already taken, choose another please'
                  );
                } else {
                  setError(
                    'Something went wrong... Try again a bit later please.'
                  );
                }
              }
            })
            .catch(() => {
              setError('Something went wrong... Try again a bit later please.');
            });
        }}
      >
        {squad ? 'SAVE CHANGES' : 'CREATE YOUR SQUAD'}
      </Button>
    </section>
  );
};

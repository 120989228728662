import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useTourStepCompleteMutation } from '../../app/store';

import { TourStepIntro } from '../../features/tour/steps/TourStepIntro';
import { TourStepCompleted } from '../../features/tour/steps/TourStepCompleted';

import { useAppProvider } from './app-context';
import { useRouterProvider, ROUTES } from './router-context';

export const TourContext = createContext({});

export const TOUR_STEPS = {
  INTRO: 'intro',
  CLAIM_START: 'claim-start',
  CLAIM_GO_TO_STAKE: 'claim-go-to-stake',
  STAKE_CHOOSE_PLACE: 'stake-choose-place',
  STAKE_START_STAKING: 'stake-start-staking',
  STAKE_CHOOSE_STAKE_LEVEL: 'stake-choose-stake-level',
  STAKE_STAKE_COINS: 'stake-stake-coins',
  STAKE_YOUR_STAKES_INTRO: 'stake-your-stakes-intro',
  STAKE_YOUR_STAKES_DIG_UP: 'stake-your-stakes-dig-up',
  STAKE_ROB_STAKE_INTRO: 'stake-rob-stake-intro',
  STAKE_ROB_STAKE_CHOOSE: 'stake-rob-stake-choose',
  TOUR_COMPLETED: 'tour-completed',
};

const tourStepsPosition = [
  TOUR_STEPS.INTRO,
  TOUR_STEPS.CLAIM_START,
  TOUR_STEPS.CLAIM_GO_TO_STAKE,
  TOUR_STEPS.STAKE_CHOOSE_PLACE,
  TOUR_STEPS.STAKE_START_STAKING,
  TOUR_STEPS.STAKE_CHOOSE_STAKE_LEVEL,
  TOUR_STEPS.STAKE_STAKE_COINS,
  TOUR_STEPS.STAKE_YOUR_STAKES_INTRO,
  TOUR_STEPS.STAKE_YOUR_STAKES_DIG_UP,
  TOUR_STEPS.STAKE_ROB_STAKE_INTRO,
  TOUR_STEPS.STAKE_ROB_STAKE_CHOOSE,
  TOUR_STEPS.TOUR_COMPLETED,
];

const isTourEnabled = (profile) => profile?.tour_finished === false;

export const TourProvider = ({ children }) => {
  const { profile } = useAppProvider();
  const { setRoute } = useRouterProvider();
  const [tourStepComplete] = useTourStepCompleteMutation();

  const [tourEnabled, setTourEnabled] = useState(null);
  const [tourStep, setTourStep] = useState(TOUR_STEPS.INTRO);

  const isLoaded = useRef(false);
  const isInitialEnabled = useRef(false);

  const tourEnabledDirect = isTourEnabled(profile);

  const handleTourStepChange = useCallback(
    (step) => {
      setTourStep(step);
      tourStepComplete(tourStepsPosition.indexOf(step));
    },
    [tourStepComplete]
  );

  const value = useMemo(() => {
    const isEnabled = tourEnabled ?? tourEnabledDirect;

    return {
      tourEnabled: isEnabled,
      tourStep,
      setTourStep: handleTourStepChange,
      tourModals: isEnabled ? (
        <>
          <TourStepIntro
            visible={tourStep === TOUR_STEPS.INTRO}
            onNext={() => handleTourStepChange(TOUR_STEPS.CLAIM_START)}
          />

          <TourStepCompleted
            visible={tourStep === TOUR_STEPS.TOUR_COMPLETED}
            onNext={() => {
              tourStepComplete(12)
                .unwrap()
                .then(() => setRoute(ROUTES.EARN));
            }}
          />
        </>
      ) : null,
    };
  }, [
    tourEnabledDirect,
    tourStep,
    tourEnabled,
    handleTourStepChange,
    tourStepComplete,
    setRoute,
  ]);

  useEffect(() => {
    if (!profile) {
      return;
    }

    if (profile.tour_finished) {
      if (isInitialEnabled.current) {
        setTourEnabled(false);
      }
      return;
    }

    if (isLoaded.current) {
      return;
    }

    isLoaded.current = true;

    const isEnabled = isTourEnabled(profile);

    if (!isEnabled) {
      return;
    }

    const step = tourStepsPosition[profile.tour_step ?? 0];

    setTourEnabled(isEnabled);
    setTourStep(step);

    isInitialEnabled.current = true;

    if (
      [
        TOUR_STEPS.STAKE_CHOOSE_PLACE,
        TOUR_STEPS.STAKE_START_STAKING,
        TOUR_STEPS.STAKE_CHOOSE_STAKE_LEVEL,
        TOUR_STEPS.STAKE_STAKE_COINS,
        TOUR_STEPS.STAKE_YOUR_STAKES_INTRO,
        TOUR_STEPS.STAKE_YOUR_STAKES_DIG_UP,
        TOUR_STEPS.STAKE_ROB_STAKE_INTRO,
        TOUR_STEPS.STAKE_ROB_STAKE_CHOOSE,
      ].includes(step)
    ) {
      setRoute(ROUTES.STAKING);

      if (
        [
          TOUR_STEPS.STAKE_CHOOSE_PLACE,
          TOUR_STEPS.STAKE_START_STAKING,
          TOUR_STEPS.STAKE_CHOOSE_STAKE_LEVEL,
          TOUR_STEPS.STAKE_STAKE_COINS,
        ].includes(step)
      ) {
        setTourStep(TOUR_STEPS.STAKE_CHOOSE_PLACE);
      } else if ([TOUR_STEPS.STAKE_YOUR_STAKES_DIG_UP].includes(step)) {
        setTourStep(TOUR_STEPS.STAKE_YOUR_STAKES_INTRO);
      } else if ([TOUR_STEPS.STAKE_ROB_STAKE_CHOOSE].includes(step)) {
        setTourStep(TOUR_STEPS.STAKE_ROB_STAKE_INTRO);
      }
    }
  }, [profile, setRoute]);

  return (
    <TourContext.Provider value={value}>
      {children}

      {tourEnabled && false && (
        <>
          <TourStepIntro
            visible={tourStep === TOUR_STEPS.INTRO}
            onNext={() => handleTourStepChange(TOUR_STEPS.CLAIM_START)}
          />

          <TourStepCompleted
            visible={tourStep === TOUR_STEPS.TOUR_COMPLETED}
            onNext={() => {
              tourStepComplete(12)
                .unwrap()
                .then(() => setRoute(ROUTES.EARN));
            }}
          />
        </>
      )}
    </TourContext.Provider>
  );
};

export const useTourProvider = () => useContext(TourContext);

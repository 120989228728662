import { formatScore } from '../../../../../shared/helpers/formatters';
import { GoldDrillIcon } from '../../../../../shared/icons/GoldDrillIcon';
import { Button } from '../../../../../shared/ui/Button';

import s from './DigUpYourCoinsModal.module.css';

export const DigUpYourCoinsModal = ({ visible, amount, profit, onClose }) => {
  return (
    <section className={`${s.outer} ${visible ? s.visible : ''}`}>
      <div className={s.inner}>
        <div className={s.amount}>
          {formatScore(amount || 0)} <GoldDrillIcon />
        </div>

        <div className={s.heading}>TURNED INTO</div>

        <div className={s.amountProfit}>
          {formatScore((amount || 0) + (profit || 0))} <GoldDrillIcon />
        </div>

        <Button className={s.btn} onClick={onClose}>
          CLAIM!
        </Button>
      </div>
    </section>
  );
};

import {
  useRouterProvider,
  ROUTES,
} from '../../../shared/contexts/router-context';
import {
  useTourProvider,
  TOUR_STEPS,
} from '../../../shared/contexts/tour-context';
import { GoldDrillIcon } from '../../../shared/icons/GoldDrillIcon';
import { RoadFinishFillIcon } from '../../../shared/icons/RoadFinishFillIcon';
import { PercentIcon } from '../../../shared/icons/PercentIcon';
import { GroupFillIcon } from '../../../shared/icons/GroupFillIcon';
import { PickaxeIcon } from '../../../shared/icons/PickaxeIcon';
import { ArrowRightIcon } from '../../../shared/icons/ArrowRightIcon';

import { TabBarItem } from './TabBarItem';

import s from './TabBar.module.css';

export const TabBar = () => {
  const { route, setRoute } = useRouterProvider();
  const { tourEnabled, tourStep, setTourStep } = useTourProvider();

  const isTourStakeNavigate =
    tourEnabled && tourStep === TOUR_STEPS.CLAIM_GO_TO_STAKE;
  const disabledRoutes = isTourStakeNavigate
    ? [
        ROUTES.EARN,
        ROUTES.QUESTS_ACTIVE,
        ROUTES.REFERAL,
        ROUTES.LEADERBOARD_SQUADS,
      ]
    : [];
  const isDisabled = tourEnabled && !isTourStakeNavigate;

  const handleRouteClick = (route) => {
    if (!disabledRoutes.includes(route)) {
      setRoute(route);

      if (isTourStakeNavigate) {
        setTourStep(TOUR_STEPS.STAKE_CHOOSE_PLACE);
      }
    }
  };

  return (
    <section className={`${s.outer} ${isDisabled ? s.disabled : ''}`}>
      {isTourStakeNavigate && (
        <div className={s.tourIntro}>
          <div className={s.tourIntroText}>come here</div>
          <ArrowRightIcon />
        </div>
      )}

      <div className={s.inner}>
        <TabBarItem
          icon={GoldDrillIcon}
          label="EARN"
          active={route === ROUTES.EARN}
          onClick={() => handleRouteClick(ROUTES.EARN)}
        />

        <TabBarItem
          icon={RoadFinishFillIcon}
          label="Quests"
          active={
            route === ROUTES.QUESTS_ACTIVE || route === ROUTES.QUESTS_COMPLETED
          }
          onClick={() => handleRouteClick(ROUTES.QUESTS_ACTIVE)}
        />

        <TabBarItem
          icon={PickaxeIcon}
          label="STAKING"
          active={route === ROUTES.STAKING}
          onClick={() => handleRouteClick(ROUTES.STAKING)}
        />

        <TabBarItem
          icon={PercentIcon}
          label="Referal"
          active={route === ROUTES.REFERAL}
          onClick={() => handleRouteClick(ROUTES.REFERAL)}
        />

        <TabBarItem
          icon={GroupFillIcon}
          label="LeaderS"
          active={
            route === ROUTES.LEADERBOARD_FRIENDS ||
            route === ROUTES.LEADERBOARD_SQUADS
          }
          onClick={() => handleRouteClick(ROUTES.LEADERBOARD_SQUADS)}
        />
      </div>
    </section>
  );
};

export const ExitIcon = (props) => {
  return (
    <svg
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.82665 0.48009C4.18647 -0.160193 3.14843 -0.160193 2.50825 0.48009L0.480578 2.50809C-0.159454 3.14822 -0.159455 4.18598 0.480578 4.82612L4.42555 8.77173C5.06559 9.41187 5.06559 10.4496 4.42555 11.0898L0.480024 15.0359C-0.160008 15.6761 -0.160008 16.7138 0.480025 17.354L2.50769 19.382C3.14787 20.0222 4.18592 20.0222 4.8261 19.382L8.77088 15.4365C9.41106 14.7963 10.4491 14.7963 11.0893 15.4365L15.1744 19.5223C15.8146 20.1626 16.8526 20.1626 17.4928 19.5223L19.5205 17.4943C20.1605 16.8542 20.1605 15.8164 19.5205 15.1763L15.4346 11.0898C14.7946 10.4496 14.7946 9.41187 15.4346 8.77173L19.3794 4.82627C20.0195 4.18613 20.0195 3.14838 19.3794 2.50824L17.3518 0.480245C16.7116 -0.160038 15.6735 -0.160036 15.0333 0.480246L11.0893 4.42495C10.4491 5.06524 9.41106 5.06524 8.77088 4.42495L4.82665 0.48009Z"
        fill="#D2410E"
      />
    </svg>
  );
};

export const CheckBoxIcon = (props) => {
  return (
    <svg
      height="1em"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_786_4423)">
        <rect y="0.5" width="23" height="23" rx="3" fill="#3C2521" />
        <g filter="url(#filter0_d_786_4423)">
          <path
            d="M8.97851 14.9306C8.63893 15.2702 8.08835 15.2702 7.74876 14.9306L4.20579 11.3876C3.8662 11.048 3.31562 11.048 2.97603 11.3876L2.61488 11.7488C2.27529 12.0883 2.27529 12.6389 2.61488 12.9785L7.74876 18.1124C8.08835 18.452 8.63893 18.452 8.97851 18.1124L21.3851 5.70578C21.7247 5.3662 21.7247 4.81562 21.3851 4.47603L21.024 4.11488C20.6844 3.77529 20.1338 3.77529 19.7942 4.11488L8.97851 14.9306Z"
            fill="#FFF2E3"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_786_4423"
          x="2.36035"
          y="3.86011"
          width="19.2793"
          height="15.3766"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.869565" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_786_4423"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_786_4423"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_786_4423">
          <rect
            width="23"
            height="23"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

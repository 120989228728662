import { isNull } from '../../../shared/helpers/validators';

export const getPercent = (duration, left) => {
  if (isNull(duration) || isNull(left)) {
    return null;
  }

  return Math.min(left / (duration / 100), 100);
};

export const getTimeLabel = (duration, left, onlySeconds) => {
  if (isNull(duration) || isNull(left)) {
    return 'Claim';
  }
  if (duration <= left) {
    return 'Finish claim';
  }
  const seconds = Math.max(duration - left, 0);
  const hours = Math.floor(seconds / 60 / 60);
  const minutes = Math.floor((seconds - hours * 60 * 60) / 60);
  const secondsLeft = Math.floor(seconds - hours * 60 * 60 - minutes * 60);
  return onlySeconds ? `${secondsLeft} sec` : `Claim in ${hours}hr ${minutes}m`;
};

export const defaultAnimState = {
  scale: 1,
  translateZ: 0,
  rotateX: 0,
  rotateY: 0,
};

export const getAnimStyle = (o) =>
  `scale(${o.scale}) translateZ(${o.translateZ}px) rotateX(${o.rotateX}deg) rotateY(${o.rotateY}deg)`;

export const rnd = (min, max) => Math.random() * (max - min) + min;
export const randomInt = (min, max) => Math.round(rnd(min, max));

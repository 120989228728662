import { Button } from '../../../../../shared/ui/Button';

import s from './StakeEmptyModal.module.css';

export const StakeEmptyModal = ({ visible, onClose }) => {
  return (
    <section className={`${s.outer} ${visible ? s.visible : ''}`}>
      <div className={s.inner}>
        <div className={s.heading}>YOU HAVE NOTHING TO CLAIM</div>

        <div className={s.body}>THIS STAKE IS EMPTY</div>

        <Button className={s.btn} onClick={onClose}>
          OK
        </Button>
      </div>
    </section>
  );
};

import { createSlice, configureStore } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_URL } from '../../shared/config';

export const AUTH_STATUSES = {
  NOT_INITIALIZED: 'NOT_INITIALIZED',
  NO_INITIAL_DATA: 'NO_INITIAL_DATA',
  FETCHING: 'FETCHING',
  FETCH_ERROR: 'FETCH_ERROR',
  AUTHORIZE_ERROR: 'AUTHORIZE_ERROR',
  AUTHORIZED: 'AUTHORIZED',
};

const appSlice = createSlice({
  name: 'app',
  initialState: {
    authToken: null,
    authStatus: AUTH_STATUSES.NOT_INITIALIZED,
  },
  reducers: {
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    setAuthStatus: (state, action) => {
      state.authStatus = action.payload;
    },
  },
});

const appApi = createApi({
  reducerPath: 'appApi',
  tagTypes: [
    'Profile',
    'Quest',
    'Friend',
    'Referrals',
    'Squad',
    'DiggyGameData',
    'DiggyGameStatus',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders(headers, api) {
      const token = getAuthToken(api.getState());
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    fetchAuthToken: builder.mutation({
      query: (body) => ({ url: '/auth/token', method: 'POST', body }),
    }),
    fetchCurrentProfile: builder.query({
      query: () => '/profile',
      providesTags: ['Profile'],
    }),
    fetchMyQuests: builder.query({
      query: () => '/quests',
      providesTags: ['Quest'],
    }),
    passQuest: builder.mutation({
      query: (id) => ({ url: `/quests/${id}/pass`, method: 'PUT' }),
      invalidatesTags: ['Quest', 'Profile', 'Squad'],
    }),
    fetchFriends: builder.query({
      query: () => '/friends',
      providesTags: ['Friend'],
    }),
    startClaim: builder.mutation({
      query: () => ({ url: '/claim', method: 'POST' }),
      invalidatesTags: ['Profile'],
    }),
    completeClaim: builder.mutation({
      query: () => ({ url: '/claim/complete', method: 'POST' }),
      invalidatesTags: ['Profile', 'Squad'],
    }),
    fetchReferrals: builder.query({
      query: () => '/referrals',
      providesTags: ['Referrals'],
    }),
    storeTapPoints: builder.mutation({
      query: (body) => ({ url: '/points/taps', method: 'POST', body }),
      invalidatesTags: ['Profile', 'Squad'],
    }),
    storeSquad: builder.mutation({
      query: (body) => ({ url: '/squads', method: 'POST', body }),
      invalidatesTags: ['Profile', 'Squad'],
    }),
    joinToSquad: builder.mutation({
      query: (id) => ({ url: `/squads/${id}`, method: 'PUT' }),
      invalidatesTags: ['Profile', 'Squad'],
    }),
    leaveJoinedSquad: builder.mutation({
      query: () => ({ url: '/squads/joined', method: 'DELETE' }),
      invalidatesTags: ['Profile', 'Squad'],
    }),
    fetchMostActiveSquads: builder.query({
      query: (v) => '/squads?v=' + (v || Date.now()),
      providesTags: ['Squad'],
    }),
    fetchMostActiveSquadMembers: builder.query({
      query: (id) => `/squads/${id}/most-active`,
      providesTags: ['Squad'],
    }),
    fetchDiggyGameData: builder.query({
      query: () => '/games/diggy/data',
      providesTags: ['DiggyGameData'],
    }),
    fetchDiggyGameStatus: builder.query({
      query: (v) => '/games/diggy/status?v=' + v,
      providesTags: ['DiggyGameStatus'],
    }),
    diggyGameMakeStake: builder.mutation({
      query: (body) => ({ url: '/games/diggy/stake', method: 'POST', body }),
      invalidatesTags: ['Profile', 'DiggyGameStatus', 'Squad'],
    }),
    diggyGameDigUp: builder.mutation({
      query: (stakeId) => ({
        url: `/games/diggy/dig-up/${stakeId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Profile', 'DiggyGameStatus', 'Squad'],
    }),
    tourStepComplete: builder.mutation({
      query: (stepId) => ({
        url: `/tour/step/${stepId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Profile'],
    }),
  }),
});

export const store = configureStore({
  reducer: {
    [appSlice.name]: appSlice.reducer,
    [appApi.reducerPath]: appApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(appApi.middleware),
});

export const {
  useFetchAuthTokenMutation,
  useFetchCurrentProfileQuery,
  useFetchMyQuestsQuery,
  usePassQuestMutation,
  useFetchFriendsQuery,
  useStartClaimMutation,
  useCompleteClaimMutation,
  useFetchReferralsQuery,
  useStoreTapPointsMutation,
  useStoreSquadMutation,
  useJoinToSquadMutation,
  useLeaveJoinedSquadMutation,
  useFetchMostActiveSquadsQuery,
  useFetchMostActiveSquadMembersQuery,
  useFetchDiggyGameDataQuery,
  useFetchDiggyGameStatusQuery,
  useDiggyGameMakeStakeMutation,
  useDiggyGameDigUpMutation,
  useTourStepCompleteMutation,
} = appApi;

export const getAuthToken = (state) => state.app.authToken;
export const getAuthStatus = (state) => state.app.authStatus;

export const setAuthToken = appSlice.actions.setAuthToken;
export const setAuthStatus = appSlice.actions.setAuthStatus;

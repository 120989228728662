import { formatScore } from '../../../../../shared/helpers/formatters';
import { GoldDrillIcon } from '../../../../../shared/icons/GoldDrillIcon';
import { Button } from '../../../../../shared/ui/Button';

import { SeparatorIcon } from './SeparatorIcon';

import s from './StolenTreasureModal.module.css';

export const StolenTreasureModal = ({ visible, stakes, onClose }) => {
  const totalAmount = (stakes || []).reduce(
    (acc, stake) => acc + stake.amount,
    0
  );

  return (
    <section className={`${s.outer} ${visible ? s.visible : ''}`}>
      <div className={s.inner}>
        <div className={s.heading}>YOUR TREASURE WAS STOLEN!</div>

        <div className={s.coordinates}>
          {(stakes || []).map((stake) => (
            <div className={s.coordinate} key={stake.id}>
              <div className={s.coordinateItem}>{stake.pos_x + 1}</div>

              <div className={s.coordinateSeparator}>
                <SeparatorIcon />
              </div>

              <div className={s.coordinateItem}>{stake.pos_y + 1}</div>
            </div>
          ))}
        </div>

        <div className={s.amount}>
          {formatScore(totalAmount)} <GoldDrillIcon />
        </div>

        <Button className={s.btn} onClick={onClose}>
          OK
        </Button>
      </div>
    </section>
  );
};

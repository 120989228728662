export const CellPosDelimiterIcon = () => {
  return (
    <svg
      width="3"
      height="7"
      viewBox="0 0 3 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.515985 2.64294C0.184401 2.64294 0.131348 2.2583 0.131348 1.68797C0.131348 1.4625 0.184401 0.945225 0.184401 0.653431C0.184401 0.255531 0.396615 0.229004 0.595565 0.229004H2.01474C2.09432 0.229004 2.29328 0.401428 2.29328 0.653431C2.29328 1.23702 2.3198 1.35639 2.3198 1.97977C2.3198 2.24503 2.20043 2.57662 2.01474 2.57662C1.78927 2.57662 1.3781 2.64294 0.515985 2.64294ZM0.515985 6.90047C0.184401 6.90047 0.131348 6.51584 0.131348 5.94551C0.131348 5.72003 0.184401 5.20276 0.184401 4.91097C0.184401 4.51307 0.396615 4.48654 0.595565 4.48654H2.01474C2.09432 4.48654 2.29328 4.65897 2.29328 4.91097C2.29328 5.49456 2.3198 5.61393 2.3198 6.23731C2.3198 6.50257 2.20043 6.83416 2.01474 6.83416C1.78927 6.83416 1.3781 6.90047 0.515985 6.90047Z"
        fill="white"
      />
    </svg>
  );
};

import {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import {
  useFetchDiggyGameDataQuery,
  useFetchDiggyGameStatusQuery,
} from '../../app/store';
import { useAppProvider } from './app-context';

export const DiggyGameContext = createContext({});

export const DiggyGameProvider = ({ children }) => {
  const { isAuthorized } = useAppProvider();

  const [cacheKey, setCacheKey] = useState(0);
  const [reloadDelay, setReloadDelay] = useState(10000);

  const [selectedCell, setSelectedCell] = useState(null);

  const opts = { skip: !isAuthorized };

  const { data } = useFetchDiggyGameDataQuery(null, opts);
  const { data: status } = useFetchDiggyGameStatusQuery(cacheKey, opts);

  const forceReloadStatus = useCallback((force) => {
    setReloadDelay(force ? 2000 : 10000);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCacheKey(Date.now());
    }, reloadDelay);

    return () => clearInterval(timer);
  }, [reloadDelay]);

  const value = useMemo(() => {
    return {
      map: data?.map || [],
      digLevels: data?.dig_levels ?? [],
      energy: status?.energy || 0,
      energyMax: status?.energy_max || 0,
      stakes: status?.stakes || [],
      stakesStolen: status?.stakes_stolen || null,
      selectedCell,
      setSelectedCell,
      forceReloadStatus,
    };
  }, [data, status, forceReloadStatus, selectedCell]);

  return (
    <DiggyGameContext.Provider value={value}>
      {children}
    </DiggyGameContext.Provider>
  );
};

export const useDiggyGameProvider = () => useContext(DiggyGameContext);

import { ExitIcon } from '../../../../../shared/icons/ExitIcon';

import s from './IntroModal.module.css';

export const IntroModal = ({ visible, onClose }) => {
  return (
    <section className={`${s.outer} ${visible ? s.visible : ''}`}>
      <div className={s.inner}>
        <div className={s.heading}>STAKING</div>

        <div className={s.close} onClick={onClose}>
          <ExitIcon />
        </div>

        <div className={s.content}>
          Here's what you can do here: <br />- Choose a field cell and its depth
          in order <span>to multiply your coins</span>. Each depth level has its
          own characteristics, be careful when choosing. <br />
          <span>
            Do not bury all your coins in one cell, they can be dug up by the
            others!
          </span>
          <br /> - Try your luck and dig up other people's buried coins, getting
          75% of the found treasure.
        </div>

        <div className={s.footer}>Good luck!</div>
      </div>
    </section>
  );
};

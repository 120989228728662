import { ArrowAltRightIcon } from '../../../shared/icons/ArrowAltRightIcon';
import { DoneRoundLightIcon } from '../../../shared/icons/DoneRoundLightIcon';
import { GoldDrillIcon } from '../../../shared/icons/GoldDrillIcon';
import { formatScore } from '../../../shared/helpers/formatters';
import { BASE_URL } from '../../../shared/config';

import {
  useFetchMyQuestsQuery,
  usePassQuestMutation,
} from '../../../app/store';

import './QuestsView.css';

const iconsMap = {
  telegram: '/assets/soc-icon-telegram.png',
  twitter: '/assets/soc-icon-x.png',
  discord: '/assets/soc-icon-discord.png',
  mycorp: '/assets/soc-icon-my-corp.png',
  mrsailer: '/assets/soc-icon-mr-sailer.jpeg',
  tappak: '/assets/soc-icon-tap-pak.jpeg',
  starai: '/assets/soc-icon-star-ai.png',
  titan: '/assets/soc-icon-titan.png',
  maincard: '/assets/soc-icon-maincard.png',
};

export const QuestsView = ({ completed }) => {
  const [passQuest] = usePassQuestMutation();
  const { data = { quests: [] } } = useFetchMyQuestsQuery();
  const quests = (data.quests || []).filter((q) => q.passed === !!completed);

  return (
    <section className="quests-view">
      {quests.length ? (
        quests.map((q) => (
          <QuestItem
            key={q.id}
            icon={iconsMap[q.icon]}
            text={q.title}
            amount={q.amount_points}
            link={q.link}
            completed={q.passed}
            onPass={() => {
              passQuest(q.id);
            }}
          />
        ))
      ) : (
        <div className="quests-view__empty">
          <div className="quests-view__empty-text">
            {completed
              ? 'None of the quests have been completed yet'
              : 'None of the available quests yet'}
          </div>
        </div>
      )}
    </section>
  );
};

const QuestItem = ({ icon, text, amount, link, completed, onPass }) => {
  return (
    <div
      className={`quests-view__item ${
        completed ? 'quests-view__item--completed' : ''
      }`}
    >
      <img src={BASE_URL + icon} className="quests-view__item-icon" alt="" />

      <div className="quests-view__item-body">
        <div className="quests-view__item-text">{text}</div>

        <div className="quests-view__item-amount">
          <GoldDrillIcon className="quests-view__item-amount-icon" />

          <div className="quests-view__item-amount-value">
            +{formatScore(amount)}
          </div>
        </div>
      </div>

      {completed ? (
        <button className="quests-view__item-btn">
          <DoneRoundLightIcon />
        </button>
      ) : (
        <a
          href={link}
          className="quests-view__item-btn"
          target="_blank"
          rel="noreferrer"
          onClick={onPass}
        >
          <ArrowAltRightIcon />
        </a>
      )}
    </div>
  );
};

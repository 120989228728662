import { ArrowRightIcon } from '../../../../shared/icons/ArrowRightIcon';

import s from './TourStepCompleted.module.css';

export const TourStepCompleted = ({ visible, onNext }) => {
  return (
    <section className={`${s.outer} ${visible ? s.visible : ''}`}>
      <div className={s.text}>
        Now you are ready for digging. <br />
        Stay in touch and wait for updates. <br />
        Good luck
      </div>

      <div className={s.arrow} onClick={onNext}>
        <ArrowRightIcon />
      </div>
    </section>
  );
};

const tgWebApp = window?.Telegram?.WebApp;

export const ready = () => {
  if (tgWebApp) {
    tgWebApp.ready();
    if (
      tgWebApp.disableVerticalSwipes &&
      typeof tgWebApp.disableVerticalSwipes === 'function'
    ) {
      tgWebApp.disableVerticalSwipes();
    }
  }
};

export const close = () => {
  if (tgWebApp) {
    tgWebApp.close();
  }
};

export const expand = () => {
  if (tgWebApp) {
    tgWebApp.expand();
  }
};

export const openTelegramLink = (link) => {
  if (tgWebApp) {
    tgWebApp.openTelegramLink(link);
  }
};

export const getInitialData = () => (tgWebApp ? tgWebApp.initData : null);

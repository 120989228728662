export const PickaxeIcon = (props) => {
  return (
    <svg
      height="1em"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.2662 18.1427L5.97907 37.4127L3.5874 35.021L22.8574 15.7339L25.2662 18.1427ZM32.9195 13.2056L33.9274 12.1977L32.5778 10.8481L33.6712 9.75474L31.2453 7.32891L30.152 8.42224L28.8024 7.07266L27.7945 8.08058C24.822 5.65474 21.4737 3.70724 17.8862 2.34058L16.4682 5.39849C19.4578 6.97016 22.2082 8.86641 24.7707 11.1043L23.9166 11.9585L29.0416 17.0835L29.8957 16.2293C32.1337 18.7918 34.0299 21.5422 35.6016 24.5318L38.6595 23.1139C37.2928 19.5264 35.3453 16.1781 32.9195 13.2056Z"
        fill="currentColor"
      />
    </svg>
  );
};

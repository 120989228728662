import { formatScore } from '../../../../../shared/helpers/formatters';
import { GoldDrillIcon } from '../../../../../shared/icons/GoldDrillIcon';
import { Button } from '../../../../../shared/ui/Button';

import s from './StakedCoinsModal.module.css';

export const StakedCoinsModal = ({ visible, amount, level, onClose }) => {
  return (
    <section
      className={`${s.outer} ${visible && amount && level ? s.visible : ''}`}
    >
      <div className={s.inner}>
        <div className={s.heading}>YOU HAVE STAKED YOUR COINS!</div>

        <div className={s.amount}>
          {formatScore(amount || 0)} <GoldDrillIcon />
        </div>

        <div className={s.desc}>
          {`deep ${level?.level || 0} - ${
            level?.percent_per_hour || 0
          }% per h / ${level?.percent_per_day || 0}% per 24h`}
        </div>

        <Button className={s.btn} onClick={onClose}>
          GREAT!
        </Button>
      </div>
    </section>
  );
};

import { Scene } from 'phaser';

import { textures } from '../resources';
import { TAIL_SIZE } from '../constants';

export class BootScene extends Scene {
  constructor() {
    super('BootScene');
  }

  init() {
    const sceneWidth = this.scale.width;
    const sceneHeight = this.scale.height;
    const recWidth = sceneWidth * 0.7;
    const recHeight = 32;
    const recCenterX = sceneWidth / 2;
    const recCenterY = sceneHeight / 2;
    const recStrokeWidth = 1;
    const recPadding = 1;
    const barInitialWidth = 4;
    const progressColor = 0xffffff;
    const barOffset = recStrokeWidth * 2 + recPadding * 2;
    const barMaxWidth = recWidth - barOffset;

    this.add
      .rectangle(recCenterX, recCenterY, recWidth, recHeight)
      .setStrokeStyle(recStrokeWidth, progressColor);

    const bar = this.add.rectangle(
      recCenterX - (barMaxWidth / 2 - barOffset / 2),
      recCenterY,
      barInitialWidth,
      recHeight - barOffset,
      progressColor
    );

    this.load.on('progress', (progress) => {
      bar.width = barMaxWidth * progress;
    });
  }

  preload() {
    this.load.setPath('assets/diggy-game');

    Object.values(textures).forEach((res) => {
      const chunks = res.src.split('.');
      if (!res.name) {
        res.name = chunks[0];
      }
      if (chunks[1] === 'svg') {
        this.load.svg(res.name, res.src, { scale: 10 });
      } else {
        this.load.image(res.name, res.src);
      }
    });
  }

  create() {
    Object.values(textures).forEach((res) => {
      const texture = this.textures.get(res.name);
      const frame = texture.getSourceImage();
      res.width = frame.width;
      res.height = frame.height;
      if (res.width !== TAIL_SIZE) {
        res.baseWidth = TAIL_SIZE;
      }
      if (res.height !== TAIL_SIZE) {
        res.baseHeight = TAIL_SIZE;
      }
    });
    this.scene.start('WorldScene');
  }
}

export const SeparatorIcon = () => {
  return (
    <svg
      width="5"
      height="13"
      viewBox="0 0 5 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.2017 4.81335C0.595024 4.81335 0.497955 4.1096 0.497955 3.06612C0.497955 2.65357 0.595024 1.70715 0.595024 1.17328C0.595024 0.445263 0.983299 0.396729 1.34731 0.396729H3.94389C4.0895 0.396729 4.4535 0.712202 4.4535 1.17328C4.4535 2.24103 4.50204 2.45944 4.50204 3.59999C4.50204 4.08534 4.28363 4.69202 3.94389 4.69202C3.53135 4.69202 2.77907 4.81335 1.2017 4.81335ZM1.2017 12.6031C0.595024 12.6031 0.497955 11.8994 0.497955 10.8559C0.497955 10.4433 0.595024 9.49691 0.595024 8.96304C0.595024 8.23502 0.983299 8.18649 1.34731 8.18649H3.94389C4.0895 8.18649 4.4535 8.50196 4.4535 8.96304C4.4535 10.0308 4.50204 10.2492 4.50204 11.3898C4.50204 11.8751 4.28363 12.4818 3.94389 12.4818C3.53135 12.4818 2.77907 12.6031 1.2017 12.6031Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const tileTypes = {
  GRASS: 'GRASS',
  SAND: 'SAND',
  STONE: 'STONE',
  WATER: 'WATER',
  SYMBOL: 'SYMBOL',
  PATH: 'PATH',
};

export const textures = {
  GRASS_1: {
    type: tileTypes.GRASS,
    src: 'grass-1.png',
    offsetTop: 3,
    offsetLeft: 7,
  },
  GRASS_1_1: {
    type: tileTypes.GRASS,
    src: 'grass-1.png',
    name: 'grass-1-1',
    offsetTop: 6,
    offsetLeft: 3,
  },
  GRASS_2: {
    type: tileTypes.GRASS,
    src: 'grass-2.png',
    offsetTop: 3,
    offsetLeft: 2,
  },
  GRASS_2_1: {
    type: tileTypes.GRASS,
    src: 'grass-2.png',
    name: 'grass-2-1',
    offsetTop: 4,
    offsetLeft: 4,
  },
  GRASS_3: {
    type: tileTypes.GRASS,
    src: 'grass-3.png',
    offsetTop: 6,
    offsetLeft: 3,
  },
  GRASS_3_1: {
    type: tileTypes.GRASS,
    src: 'grass-3.png',
    name: 'grass-3-1',
    offsetTop: 2,
    offsetLeft: 2,
  },
  SYMBOL_1: {
    type: tileTypes.SYMBOL,
    src: 'symbol-1.png',
    offsetTop: 6,
    offsetLeft: 8,
  },
  SYMBOL_2: {
    type: tileTypes.SYMBOL,
    src: 'symbol-2.png',
    offsetTop: 6,
    offsetLeft: 6,
  },
  SYMBOL_3: {
    type: tileTypes.SYMBOL,
    src: 'symbol-3.png',
    offsetTop: 6,
    offsetLeft: 6,
  },
  PATH_1: { type: tileTypes.PATH, src: 'path-1.svg' },
  SAND_1: { type: tileTypes.SAND, src: 'sand-1.png' },
  SAND_2: { type: tileTypes.SAND, src: 'sand-2.png' },
  SAND_3: { type: tileTypes.SAND, src: 'sand-3.png' },
  SAND_4: { type: tileTypes.SAND, src: 'sand-4.png' },
  STONE_1: {
    type: tileTypes.STONE,
    src: 'stone-1.png',
    offsetTop: 3,
    offsetLeft: 3,
  },
  STONE_1_1: {
    type: tileTypes.STONE,
    src: 'stone-1.png',
    name: 'stone-1-1',
    offsetTop: 10,
    offsetLeft: 8,
  },
  STONE_1_2: {
    type: tileTypes.STONE,
    src: 'stone-1.png',
    name: 'stone-1-2',
    offsetTop: 7,
    offsetLeft: 5,
  },
  STONE_2: {
    type: tileTypes.STONE,
    src: 'stone-2.png',
    offsetTop: 4,
    offsetLeft: 8,
  },
  WATER_1: { type: tileTypes.WATER, src: 'water-1.png' },
  WATER_2: { type: tileTypes.WATER, src: 'water-2.png' },
  WATER_3: { type: tileTypes.WATER, src: 'water-3.png' },
  WATER_4: { type: tileTypes.WATER, src: 'water-4.png' },
  WATER_5: { type: tileTypes.WATER, src: 'water-5.png' },
  WATER_6: { type: tileTypes.WATER, src: 'water-6.png' },
  WATER_7: { type: tileTypes.WATER, src: 'water-7.png' },
  WATER_8: { type: tileTypes.WATER, src: 'water-8.png' },
  WATER_9: { type: tileTypes.WATER, src: 'water-9.png' },
  WATER_10: { type: tileTypes.WATER, src: 'water-10.png' },
  WATER_11: { type: tileTypes.WATER, src: 'water-11.png' },
  WATER_12: { type: tileTypes.WATER, src: 'water-12.png' },
  WATER_13: { type: tileTypes.WATER, src: 'water-13.png' },
  WATER_14: { type: tileTypes.WATER, src: 'water-14.png' },
  WATER_15: { type: tileTypes.WATER, src: 'water-15.png' },
  WATER_16: { type: tileTypes.WATER, src: 'water-16.png' },
  WATER_17: { type: tileTypes.WATER, src: 'water-17.png' },
  WATER_18: { type: tileTypes.WATER, src: 'water-18.png' },
};
